import styled from '@emotion/styled';
import { lighten } from 'polished';

import { unit, colors } from '../styles';

const height = 50;
export default styled('button')({
  display: 'block',
  minWidth: 200,
  height,
  margin: `${unit * 4}px ${unit * 4}px ${unit * 4}px ${unit * 4}px`,
  padding: `0 ${unit * 4}px`,
  border: 'none',  
  borderRadius: height / 2,
  fontFamily: 'inherit',
  fontSize: 18,
  lineHeight: `${height}px`,
  fontWeight: 700,
  color: 'white',
  textTransform: 'uppercase',
  backgroundColor: colors.button,
  cursor: 'pointer',
  outline: 'none',
  ':hover': {
    backgroundColor: lighten(0.1, colors.button),
  },
  ':active': {
    backgroundColor: lighten(0.2, colors.button),
  },
});
