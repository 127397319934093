import { injectGlobal } from '@emotion/css';

export const unit = 8;
export const colors = {
  primary: '#051423',
  secondary: '#0A2744',
  accent: '#430A0A',
  button: '#4393E4',
  background: '#FFF',
  grey: '#555E68',
  text: '#000',
  positive: '#00B87C',
  negative: '#FF4D4D',
  textPrimary: '#B0B8C4',
  textSecondary: '#747790',
};

const styles = () =>
  injectGlobal({
    [['html', 'body']]: {
      height: '100%',
    },
    body: {
      margin: 0,
      padding: 0, 
      fontFamily: "'Noto Sans Arabic', sans-serif",
      backgroundColor: colors.background,
      color: colors.text,
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
    '*': {
      boxSizing: 'border-box',
    },
    [['h1', 'h2', 'h3', 'h4', 'h5', 'h6']]: {
      margin: 0,
      fontWeight: 600,
      color: colors.textPrimary,
    },
    h1: {
      fontSize: 48,
      lineHeight: 1,
    },
    h2: {
      fontSize: 40,
    },
    h3: {
      fontSize: 36,
    },
    h5: {
      fontSize: 16,
      textTransform: 'uppercase',
      letterSpacing: 4,
    },
  });

export default styles;
