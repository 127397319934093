import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react'
import { unit, colors } from '../styles';

interface ScrollTopProps {

}   

const ScrollTop: React.FC<ScrollTopProps> = () => {
  function scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  return (
    <ScrollTopButton css={{
      '&.hover': {
        backgroundColor: colors.button,
        color: colors.accent,
      }
    }} onClick={scrollToTop}>
        <ChevronUp />
    </ScrollTopButton>
  );
}  

const ScrollTopButton = styled('div')({
  position: 'fixed',
  bottom:'20px',
  right: '20px',
  backgroundColor: colors.button,
  color: colors.background,
  border:'none',
  borderRadius: '50%',
  padding: '10px',
  cursor: 'pointer',
});


const ChevronUp = styled('div')({
  width: 0,
  height: 0,
  borderLeft: `${unit * 1 }px solid transparent`,
  borderRight: `${unit * 1}px solid transparent`,
  borderBottom: `${unit * 1}px solid ${colors.background}`,
})

export default ScrollTop;
