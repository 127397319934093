import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, NormalizedCacheObject, ApolloProvider } from '@apollo/client';

import Pages from './pages/';
import injectStyles from './styles';
import { cache } from './cache';

// Set up our apollo-client to point at the server we created
// this can be local or a remote endpoint
const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  uri: process.env.REACT_APP_APOLLO_API_PATH,
  headers: {
    // authorization: localStorage.getItem('token') || '',
    'client-name': 'Twitter analytics webapp',
    'client-version': '1.0.0',
  },
  resolvers: {},
});

/**
 * Render our app
 * - We wrap the whole app with ApolloProvider, so any component in the app can
 *    make GraphqL requests. Our provider needs the client we created above,
 *    so we pass it as a prop
 * - We need a router, so we can navigate the app. We're using Reach router for this.
 *    The router chooses between which component to render, depending on the url path.
 *    ex: localhost:3000/login will render only the `Login` component
 */


// Find our rootElement or throw and error if it doesn't exist
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

injectStyles();

// Pass the ApolloClient instance to the ApolloProvider component
root.render(
  <ApolloProvider client={client}>
     <Pages />
  </ApolloProvider>,
);
