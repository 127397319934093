import React, { Fragment } from 'react';
import TweetDetail from './tweet-detail';
interface TweetProps {
  text: string;
  sentiment: string;
  id: string;
}

interface Tweet {
  tweet: TweetProps;
}

const TweetTile: React.FC<Tweet> = ({ tweet }) => {
    return (
    <Fragment>
      {tweet &&  <TweetDetail key={tweet.id} text={tweet.text} sentiment={parseFloat(tweet.sentiment)} />}
    </Fragment>
  );
};


export default TweetTile;
