import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { size } from 'polished';

import { colors } from '../styles';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.div(size(64), {
  display: 'block',
  margin: 'auto',
  border: `4px solid ${colors.grey}`,
  color: colors.secondary,
  borderTopColor: colors.primary,
  borderRadius: '50%',
  animation: `${spin} 1s linear infinite`,
});

export default Loading;
