import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import TweetList from './tweet-list';
import { Footer, PageContainer } from '../components';

export default function Pages() {
  return (
    <Router>
      <PageContainer>
        <Routes>
          <Route path="/" element={<TweetList />} />
        </Routes>
      </PageContainer>
      <Footer />
    </Router>
  );
}
