import React from 'react';
import styled from '@emotion/styled';
import { size } from 'polished';

import { unit, colors } from '../styles';
import al76 from '../assets/images/al76.png';

interface HeaderProps {
  image?: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  children?: string;
}

const Header: React.FC<HeaderProps> = ({ image, children = 'Twitter Analytics' }) => {
  return (
    <Container>
      <Image round={false} src={al76} alt="Space dog" />
      <div>
        <h2>{children}</h2>
        <Subheading>Sentiment analysis with gradient descent algorithm</Subheading>
      </div>
    </Container>
  );
};

export default Header;

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: unit * 4.5,
});

const Image = styled('img')(size(134), (props: { round: boolean }) => ({
  marginRight: unit * 2.5,
  width: '30%',
  height: '70%',
  borderRadius: props.round ? '50%' : '0%',
}));

const Subheading = styled('h5')({
  marginTop: unit / 2,
  color: colors.textSecondary,
});
