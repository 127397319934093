import React, { Fragment, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { TweetTile, Header, Button, Loading, ScrollTop } from './../components';

export const TWEETS_DATA = gql`
  query GetTweets($input: paginate) {
    tweets(input: $input) {
      nodes {
        text
        created_at
        sentiment
        id
      }
      pageInfo {
        count
        end
        start
      }
      hasMore
      totalCount
    }
  }
`;

interface Tweet {
  text: string;
  sentiment: string;
  id: string;
}

interface TweetListProps {
  
}



const TweetList: React.FC<TweetListProps> = () => {
  const skip = 0;
  const take = 10;
  const [tweets, setTweets] = useState<Tweet[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, loading, error, fetchMore } = useQuery(TWEETS_DATA, {
    variables: {
      input: {
        skip,
        take,
      },
    },
  });
  


  const handleLoadMore = async () => {
      setIsLoadingMore(true);
      const { data: moreData } = await fetchMore({
        variables: {
          input: {
            skip: data.tweets.nodes.length,
            take: take,
          },
        },
      });
      setIsLoadingMore(false);
      
      // Update the tweets state with the new data
    setTweets([...tweets, ...moreData.tweets.nodes]);
    };
  if (loading) return <Loading />;
  if (error || !data) return <p>ERROR</p>;
  


  return (
    <Fragment>
      <Header />
      {data.tweets && data.tweets.nodes.map((tweet: Tweet) => {
          return <TweetTile key={tweet.id} tweet={tweet} />;
        }
        
      )}
      {data.tweets &&
        data.tweets.hasMore &&
        (isLoadingMore ? (
          <Loading />
        ) : (
          <Button
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        ))}
      </Fragment>
  );
};

export default TweetList;
