import { InMemoryCache, Reference } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        tweets: {
          keyArgs: false,
          ...offsetLimitPagination(['skip']),
          merge(existing, incoming) {
            // const merged = existing && existing.nodes ? [...existing.nodes.slice(0), incoming.nodes] : [];
            // for (let i = 0; i < incoming.length; ++i) {
            //   if (args.variables && Number.isInteger(args.variables.input.skip)) {
            //     if (!merged.includes(incoming.nodes[i])) {
            //     merged[args.variables.input.skip + i] = incoming.nodes[i];
            //   }
            // }
            // console.log({merged});
            if (!existing) return incoming;
            if (!incoming) return existing;
            let tweets: Reference[] = [];
            if (existing && existing.tweets) {
              tweets = tweets.concat(existing.tweets);
            }
            if (incoming && incoming.tweets) {
              tweets = tweets.concat(incoming.tweets);
            }
            return {
              ...incoming,
              tweets,
            };
          }
        }
      }
    }
  }
});
