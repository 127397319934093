import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { unit, colors } from '../styles';

interface TweetDetailProps { 
  sentiment: number;
  text: string;
}

const TweetDetail: React.FC<TweetDetailProps> = ({ sentiment, text }) => (
  <Card
    style={{
      background: 'rgb(44 71 96)',
    }}
  >
    <TextContainer>{text && text} </TextContainer>
    <SentimentContainer>
      {sentiment < 0.5 && <NegativeSentiment className={'NegativeSentiment'}>{sentiment < 0.5 && 'Negative'}</NegativeSentiment>}
      {sentiment > 0.5 && <PositiveSentiment className={'PositiveSentiment'}>{sentiment > 0.5 && 'Positive'}</PositiveSentiment>}
    </SentimentContainer>
  </Card>
);

export const cardClassName = css({
  padding: `${unit * 2}px ${unit * 2}px ${unit * 2}px ${unit * 2}px`,
  borderRadius: unit * 1,
  backgroundSize: 'cover',
  backgroundColor: 'rgb(44 71 96)',
  backgroundPosition: 'center',
});
const Card = styled('div')(cardClassName, {
  height: '100%',
  marginBottom: unit * 1,
});

const SentimentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',  
  textAlign: 'left',
  alignSelf: 'flex-end',
  width: '23%',
  borderRadius: `${unit * 4}px`,
  background: colors.background,
  fontWeight: 'bold',
});

const NegativeSentiment = styled('p')({
  color: colors.negative,
  flex: 'none',
  margin: `${unit * 0.25}px ${unit * 0.25}px ${unit * 0.25}px ${unit * 0.25}px`,
  alignSelf: 'center',
});

const PositiveSentiment = styled('p')({
  flex: 'none',
  
  margin: `${unit * 0.25}px ${unit * 0.25}px ${unit * 0.25}px ${unit * 0.25}px`,
  color: colors.positive,
  alignSelf: 'center',
});

const TextContainer = styled('p')({
  flex: 'none',
  color: colors.textPrimary,
  textAlign: 'right',
  fontSize: '14px',
  textWrap: 'wrap',
  alignSelf: 'flex-start',
});

export default TweetDetail;
